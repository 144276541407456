<template>
  <div>
    <a-table bordered :data-source="list" :pagination="false">
      <a-table-column title="序号" width="50px" align="center">
          <template slot-scope="text, row, index">
              {{ index + 1 }}
          </template>
      </a-table-column>
      <a-table-column title="风险点" data-index="point"></a-table-column>
      <a-table-column title="风险描述" data-index="riskDesc"></a-table-column>
      <a-table-column title="风险类型"  data-index="type">
        <template slot-scope="type">
          <DataDictFinder dictType="qualitySafety.riskType" :dictValue="type" />
        </template>
      </a-table-column>
      <a-table-column title="风险等级" data-index="level">
        <template slot-scope="level">
          <DataDictFinder dictType="qualitySafety.riskLevel" :dictValue="level" />
        </template>
      </a-table-column>
      <a-table-column title="管控措施" data-index="measure"></a-table-column>
      <a-table-column title="责任人" data-index="managerName"></a-table-column>
      <a-table-column title="责任部门" data-index="deptName"></a-table-column>
      <a-table-column title="备注" data-index="remark"></a-table-column>
    </a-table>
  </div>
</template>

<script>
import request from "@/api/request";

function queryCompanyOrg() {
  return request({
    url: "/user-service/org/queryCompanyOrg"
  });
}

export default {
  components: {
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showBtn: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      form: this.$form.createForm(this),
      visible: false,
      organizationList: [],
      selectedUser: {},
      selectedProject: {}
    };
  },

  mounted() {
    queryCompanyOrg().then(res => {
      this.organizationList = res
    });
  }, 

  methods: {
    onSelectProject(project) {
      console.log(project);
      this.selectedProject = project;
    },
    cancel() {
      this.visible = false;
      this.form.resetFields();
      this.selectedUser = {};
      this.selectedProject = {};
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          if (!this.selectedUser.userId) {
            this.$message.error("请选择评估人");
            return;
          }
          if (!this.selectedProject.name) {
            this.$message.error("请选择项目");
            return;
          }
          this.list.push({
            deptId: values.deptId,
            deptName: this.organizationList.filter(v=>v.id == values.deptId)[0].uniqueName,
            objectId: this.selectedProject.id,
            objectName: this.selectedProject.name,
            evaluatorId: this.selectedUser.userId,
            evaluatorName: this.selectedUser.name,
          });
          this.cancel();
        }
      });
    },

    remove(index) {
      this.list.splice(index, 1);
    },
  },
};
</script>

<style lang="less" scoped>
.tips {
  color: #999;
  span {
    padding-left: 0.5em;
  }
}
</style>