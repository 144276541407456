<template>
  <div>
    <a-button
      v-if="showBtn"
      style="float: right;margin-right: 10px;margin-bottom: 10px;z-index: 9;"
      type="primary"
      @click="visible = true"
      :disabled="disabled"
      >添加</a-button
    >
    <a-table bordered :data-source="list" :pagination="false">
      <a-table-column title="序号" width="50px" align="center">
          <template slot-scope="text, row, index">
              {{ index + 1 }}
          </template>
      </a-table-column>
      <a-table-column title="风险点" data-index="point"></a-table-column>
      <a-table-column title="风险描述" data-index="riskDesc"></a-table-column>
      <a-table-column title="风险类型"  data-index="type">
        <template slot-scope="type">
          <DataDictFinder dictType="qualitySafety.riskType" :dictValue="type" />
        </template>
      </a-table-column>
      <a-table-column-group title="风险评价">
        <a-table-column title="L" data-index="likelihood"></a-table-column>
        <a-table-column title="S" data-index="severity"></a-table-column>
        <a-table-column title="R" data-index="risk"></a-table-column>
      </a-table-column-group>
      <a-table-column title="风险等级" data-index="level">
        <template slot-scope="level">
          <DataDictFinder dictType="qualitySafety.riskLevel" :dictValue="level" />
        </template>
      </a-table-column>
      <a-table-column title="管控措施" data-index="measure"></a-table-column>
      <a-table-column-group title="分级管控">
        <a-table-column title="责任人" data-index="managerName"></a-table-column>
        <a-table-column title="责任部门" data-index="deptName"></a-table-column>
        <a-table-column title="分管负责人" data-index="branchManagerName"></a-table-column>
      </a-table-column-group>
      
      <a-table-column title="备注" data-index="remark"></a-table-column>
      <a-table-column v-if="showBtn" title="操作" align="center" width="100px">
        <template slot-scope="text, record, index">
          <a
            v-if="index < list.length"
            href="#"
            :class="disabled ? '' : 'danger'"
            @click.prevent="remove(index)"
            :disabled="disabled"
            >删除</a
          >
        </template>
      </a-table-column>
    </a-table>

    <a-modal
      width="800px"
      :visible="visible"
      title="添加危险有害因素"
      :footer="null"
      @cancel="cancel"
    >
      <a-form :form="form" :label-col="{ span: 8 }"
        :wrapper-col="{ span: 12 }" :colon="false" @submit="handleSubmit" width="640px">
        <a-row>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="风险分类">
              <a-select v-decorator="[
                'category',
                {
                  rules: [
                    { required: true, message: '请输入后选择！' },
                  ],
                },
              ]" placeholder="请选择风险分类">
                <a-select-option
                  v-for="item in riskCategory"
                  :key="item.value"
                  :value="item.value"
                  >{{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="风险点">
              <a-input
                v-decorator="['point', 
                { rules: [{ required: true, message: '请输入' }] },]"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="风险类型">
              <a-select v-decorator="[
                'type',
                {
                  rules: [
                    { required: true, message: '请输入后选择！' },
                  ],
                },
              ]" placeholder="请选择风险类型">
                <a-select-option
                  v-for="item in riskType"
                  :key="item.value"
                  :value="item.value"
                  >{{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="风险等级">
              <a-select v-decorator="[
                'level',
                {
                  rules: [
                    { required: true, message: '请输入后选择！' },
                  ],
                },
              ]" placeholder="请选择风险等级">
                <a-select-option
                  v-for="item in riskLevel"
                  :key="item.value"
                  :value="item.value"
                  >{{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="责任人/责任部门">
              <div>
                <EmployeeSelector
                  @change="(arr) => {
                    if (arr.length) {
                      manager = arr[0];
                    } else {
                      manager = {};
                    }
                  }
                    "
                  :value="manager.name ? [manager] : []"
                >
                  <a-button block style="text-align: left">
                    <div v-if="manager.name">{{ manager.name }}</div>
                    <div v-else>&nbsp;</div>
                  </a-button>
                </EmployeeSelector>
              </div>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="分管负责人">
              <div>
                <EmployeeSelector
                  @change="(arr) => {
                    if (arr.length) {
                      branchManager = arr[0];
                    } else {
                      branchManager = {};
                    }
                  }
                    "
                  :value="branchManager.name ? [branchManager] : []"
                >
                  <a-button block style="text-align: left">
                    <div v-if="branchManager.name">{{ branchManager.name }}</div>
                    <div v-else>&nbsp;</div>
                  </a-button>
                </EmployeeSelector>
              </div>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="可能性(L)">
              <a-input-number
                v-decorator="['likelihood', 
                { rules: [{ required: true, message: '请输入' }] },]"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="严重性(S)">
              <a-input-number
                v-decorator="['severity', 
                { rules: [{ required: true, message: '请输入' }] },]"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="风险指数(R)">
              <a-input-number
                v-decorator="['risk', 
                { rules: [{ required: true, message: '请输入' }] },]"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="风险描述">
              <a-input
                v-decorator="['riskDesc']"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="管控措施">
              <a-input
                v-decorator="['measure']"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="备注">
              <a-input
                v-decorator="['remark']"
              />
            </a-form-item>
          </a-col>
        </a-row>
        
        <div class="right">
          <a-space>
            <a-button @click="cancel">取消</a-button>
            <a-button type="primary" html-type="submit">提交</a-button>
          </a-space>
        </div>
      </a-form>
    </a-modal>

  </div>
</template>

<script>
import EmployeeSelector from "@/components/employee-selector";
import request from "@/api/request";
import { mapGetters } from 'vuex'
function queryCompanyOrg() {
  return request({
    url: "/user-service/org/queryCompanyOrg"
  });
}
export default {
  components: {
    EmployeeSelector
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showBtn: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      form: this.$form.createForm(this),
      visible: false,
      organizationList: [],
      manager: {},
      branchManager: {},
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),

    riskType() {
      return this.findDataDict("qualitySafety.riskType");
    },
    riskLevel() {
      return this.findDataDict("qualitySafety.riskLevel");
    },
    riskCategory() {
      return this.findDataDict("qualitySafety.riskCategory");
    },

  },
  mounted() {
    queryCompanyOrg().then(res => {
      this.organizationList = res
    });
  }, 

  methods: {
    cancel() {
      this.visible = false;
      this.form.resetFields();
      this.manager = {};
      this.branchManager = {};
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          if (!this.manager.userId) {
            this.$message.error("请选择责任人");
            return;
          }
          if (!this.branchManager.userId) {
            this.$message.error("请选择分管负责人");
            return;
          }
          this.list.push({
            ...values,
            managerId: this.manager.userId,
            managerName: this.manager.name,
            deptId: this.manager.deptId,
            deptName: this.manager.deptUniqueName,
            branchManagerId: this.branchManager.userId,
            branchManagerName: this.branchManager.name,
          });
          this.cancel();
        }
      });
    },

    remove(index) {
      this.list.splice(index, 1);
    },
  },
};
</script>

<style lang="less" scoped>
.tips {
  color: #999;
  span {
    padding-left: 0.5em;
  }
}
</style>